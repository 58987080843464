import React, { useEffect, useRef, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js'
import { FiDownload } from "react-icons/fi";
import { useSelector } from 'react-redux'

import {
    useCancelSubscriptionMutation,
    useChangeUserPlanMutation,
    useDeletePaymentMethodMutation,
    useGetPaymentMethodsQuery,
    useSetDefaultPaymentMethodMutation,
    useGetUserInvoicesQuery,
    useRenewSubscriptionMutation,
    useCustomLoggerMutation
} from '../../api/api'
import {
    PLAN_NAME_TRANSLATIONS,
    PLAN_TO_INCREASED_PRICE_TRANSLATIONS,
    PLAN_TO_PRICE_TRANSLATIONS,
    PRICE_SIGN_TRANSLATIONS,
    TRANSLATIONS_INCREASED_PRICES_FOR_CHANGE_PLAN,
    TRANSLATIONS_PRICES_FOR_CHANGE_PLAN,
    UNLIMITED_PLAN_INCREASED_PRICES_TRANSLATIONS,
    UNLIMITED_PLAN_PRICES_TRANSLATIONS,
    YEARLY_PLAN_INCREASED_PRICES_TRANSLATIONS,
    YEARLY_PLAN_PRICES_TRANSLATIONS
} from '../../helpers/translation-constants';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import i18n from '../../i18n/config';

import CancelPlanConfirmationMobile from '../CancelPlanConfirmationMobile/CancelPlanConfirmationMobile'
import CancelPlanConfirmationModal from '../CancelPlanConfirmationModal/CancelPlanConfirmationModal'
import CreditCardForm from './CreditCardForm'

import overviewPanelMobileBack from '../../assets/icons/overview-panel-mobile-back.svg'
import MasterCard from '../../assets/icons/settings/mastercard.svg'
import Delete from '../../assets/icons/settings/delete.svg'

const stripePromise = loadStripe("pk_live_51NjCF0G2gC76wG4CuacboIvtDia4Pv87gp8SF7Xi2ue6gvD6kdr4BKpb1btzbgfW9d2aTXvMzSIrQQzz8PppRKDW00YBZRKLCH");

const PaymentAndPlanSettings = ({ isMobile, trialDaysLeft, setActiveTab, settings }) => {
    const [selectedChangePlan, setSelectedChangePlan] = useState('Free trial')
    const [showCancelPlanModal, setShowCancelPlanModal] = useState(false)
    const [addCardElement, setAddCardElement] = useState(false)
    const [planName, setPlanName] = useState('Free trial')
    const [changePlan, setChangePlan] = useState(false)
    const subscription = useSelector((state) => state.app.subscription)
    const user = useSelector((state) => state.app.user)

    const cancelPlanMobileBlockRef = useRef(null)

    const { t } = useTranslation()

    const navigate = useCustomNavigate()

    const { data: paymentMethods } = useGetPaymentMethodsQuery(undefined, { skip: !subscription })
    const { data: userInvoices } = useGetUserInvoicesQuery()
    const [setDefaultPaymentMethod] = useSetDefaultPaymentMethodMutation()
    const [deletePaymentMethod] = useDeletePaymentMethodMutation()
    const [renewSubscription] = useRenewSubscriptionMutation()
    const [cancelPlan] = useCancelSubscriptionMutation()
    const [changeUserPlan] = useChangeUserPlanMutation()
    const [customLogger] = useCustomLoggerMutation()

    const handleNavigateBackToMenu = () => {
        if (isMobile) {
            setActiveTab('')
        }
    }

    const handleCancelAddMethod = () => {
        setAddCardElement(false)
    }

    const handleAddPaymentMethod = () => {
        setAddCardElement(!addCardElement)
    }

    const handleNavigateToUpgradePlan = () => {
        if (planName !== 'Free trial') {
            setChangePlan(true)
        } else {
            if (subscription?.new_flow) {
                navigate('/upgrade-plan-new')
            } else {
                navigate('/upgrade-plan')
            }

            customLogger({
                action: 'click',
                description: 'Settings Upgrade Plan'
            })
        }
    }

    const handleDeletePaymentMethod = (paymentMethod) => {
        deletePaymentMethod({ payment_method_id: paymentMethod.id })
    }

    const handleSetPaymentMethodAsDefault = (paymentMethod) => {
        setDefaultPaymentMethod({ payment_method_id: paymentMethod.id })
    }

    const handleCancelPlan = () => {
        setShowCancelPlanModal(true)
    }

    const handleCancelChangePlan = () => {
        setChangePlan(false)
    }

    const handleReactivatePlan = () => {
        renewSubscription().then((res) => {
            if ('error' in res) {
                navigate('/upgrade-plan-new')
            } else {
                window.gtag('event', 'reactivate_subscription', {
                    plan_name: planName,
                    user_id: user.uid
                })
            }
        })
    }

    const handleChangePlan = async () => {
        const priceData = subscription?.increased_prices ?
            TRANSLATIONS_INCREASED_PRICES_FOR_CHANGE_PLAN[i18n.language]?.[selectedChangePlan]
            ?? TRANSLATIONS_INCREASED_PRICES_FOR_CHANGE_PLAN['en']?.[selectedChangePlan]
            : TRANSLATIONS_PRICES_FOR_CHANGE_PLAN[i18n.language]?.[selectedChangePlan]
            ?? TRANSLATIONS_PRICES_FOR_CHANGE_PLAN['en']?.[selectedChangePlan]

        changeUserPlan({
            ...priceData
        })

        setChangePlan(false)
    }

    useEffect(() => {
        const handleClick = (e) => {
            if (cancelPlanMobileBlockRef.current && !cancelPlanMobileBlockRef.current.contains(e.target)) {
                setShowCancelPlanModal(false)
            }
        }

        document.addEventListener('mousedown', handleClick)

        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [])

    useEffect(() => {
        if (subscription) {
            if (subscription.plan === 'Free') {
                setPlanName('Free trial')
                setSelectedChangePlan('Free trial')
            } else if (subscription.plan.includes('Starter')) {
                setPlanName('Starter Plan')
                setSelectedChangePlan('Starter Plan')
            } else if (subscription.plan.includes('Advanced')) {
                setPlanName('Advanced Plan')
                setSelectedChangePlan('Advanced Plan')
            } else if (subscription.plan.includes('Professional')) {
                setPlanName('Professional Plan')
                setSelectedChangePlan('Professional Plan')
            } else if (subscription.plan.includes('Limited')) {
                setPlanName('14-Days Limited Access')
                setSelectedChangePlan('14-Days Limited Access')
            } else if (subscription.plan.includes('Full')) {
                setPlanName('14-Days Full Access')
                setSelectedChangePlan('14-Days Full Access')
            } else if (subscription.plan.includes('Yearly')) {
                setPlanName('Yearly Plan')
                setSelectedChangePlan('Yearly Plan')
            } else if (subscription.plan.includes('Unlimited')) {
                setPlanName('Unlimited Plan')
                setSelectedChangePlan('Unlimited Plan')
            } else if (subscription.plan.includes('Unlimited Access')) {
                setPlanName('Unlimited Plan')
                setSelectedChangePlan('Unlimited Plan')
            }
        }
    }, [subscription])

    return (
        <div className='settings-tab-wrapper'>
            {isMobile ? (
                <div className='settings-mobile-tab-header'>
                    <h5 onClick={handleNavigateBackToMenu} className='settings-tab-title'>
                        {isMobile && (
                            <img width={20} src={overviewPanelMobileBack} alt="" />
                        )}
                        {t("paymentsAndPlan")}
                        {isMobile && (
                            <div style={{ width: '20px' }} />
                        )}
                    </h5>
                    <span className='settings-tab-description'>
                        {t("viewAndManageYourPaymentInformationAlongWithDetails")}
                    </span>
                </div>
            ) : (
                <>
                    <h5 className='settings-tab-title'>{t("paymentsAndPlan")}</h5>
                    <span className='settings-tab-description'>
                        {t("viewAndManageYourPaymentInformationAlongWithDetails")}
                    </span>
                </>
            )}

            <div className='settings-tab-information-block gap-2'>
                {changePlan ? (
                    <>
                        <h6 className='payments-and-plan-settings-subtitle'>{t("changePlan")}</h6>
                        {subscription?.new_flow ? (
                            <>
                                {planName !== 'Yearly Plan' ? (
                                    <div onClick={() => setSelectedChangePlan(planName)} className='payments-and-plan-change-item'>
                                        <div className={`profile-settings-checkbox ${selectedChangePlan === planName ? 'checked' : ''}`} />
                                        <div className='payments-and-plan-change-item-info'>
                                            <span className='payments-and-plan-change-item-title'>
                                                {t(`${PLAN_NAME_TRANSLATIONS[planName]}`)}
                                            </span>
                                            <span className='payments-and-plan-change-item-price'>
                                                {
                                                    subscription?.increased_prices ?
                                                        UNLIMITED_PLAN_INCREASED_PRICES_TRANSLATIONS[i18n.language]
                                                        ?? UNLIMITED_PLAN_INCREASED_PRICES_TRANSLATIONS['en']
                                                        : UNLIMITED_PLAN_PRICES_TRANSLATIONS[i18n.language]
                                                        ?? UNLIMITED_PLAN_PRICES_TRANSLATIONS['en']
                                                }{t("mo")}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div onClick={() => setSelectedChangePlan('Unlimited Access')} className='payments-and-plan-change-item'>
                                        <div className={`profile-settings-checkbox ${selectedChangePlan === 'Unlimited Access' ? 'checked' : ''}`} />
                                        <div className='payments-and-plan-change-item-info'>
                                            <span className='payments-and-plan-change-item-title'>{t("unlimitedAccess")}</span>
                                            <span className='payments-and-plan-change-item-price'>
                                                {
                                                    subscription?.increased_prices ?
                                                        UNLIMITED_PLAN_INCREASED_PRICES_TRANSLATIONS[i18n.language]
                                                        ?? UNLIMITED_PLAN_INCREASED_PRICES_TRANSLATIONS['en']
                                                        : UNLIMITED_PLAN_PRICES_TRANSLATIONS[i18n.language]
                                                        ?? UNLIMITED_PLAN_PRICES_TRANSLATIONS['en']
                                                }{t("mo")}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <div onClick={() => setSelectedChangePlan('Yearly Plan')} className='payments-and-plan-change-item'>
                                    <div className={`profile-settings-checkbox ${selectedChangePlan === 'Yearly Plan' ? 'checked' : ''}`} />
                                    <div className='payments-and-plan-change-item-info'>
                                        <span className='payments-and-plan-change-item-title'>
                                            {t("yearlyPlan")}
                                        </span>
                                        <span className='payments-and-plan-change-item-price'>
                                            {
                                                subscription?.increased_prices ?
                                                    YEARLY_PLAN_INCREASED_PRICES_TRANSLATIONS[i18n.language]
                                                    ?? YEARLY_PLAN_INCREASED_PRICES_TRANSLATIONS['en']
                                                    : YEARLY_PLAN_PRICES_TRANSLATIONS[i18n.language]
                                                    ?? YEARLY_PLAN_PRICES_TRANSLATIONS['en']
                                            }{t("mo")}
                                        </span>
                                    </div>
                                    <div className='payments-and-plans-change-item-save-money'>
                                        {t("save50Percents")}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div onClick={() => setSelectedChangePlan(planName)} className='payments-and-plan-change-item'>
                                    <div className={`profile-settings-checkbox ${selectedChangePlan === planName ? 'checked' : ''}`} />
                                    <div className='payments-and-plan-change-item-info'>
                                        <span className='payments-and-plan-change-item-title'>
                                            {t(`${PLAN_NAME_TRANSLATIONS[planName]}`)}
                                        </span>
                                        <span className='payments-and-plan-change-item-price'>
                                            {
                                                PRICE_SIGN_TRANSLATIONS[i18n.language]
                                                ?? PRICE_SIGN_TRANSLATIONS['en']
                                            }
                                            {
                                                subscription?.increased_prices ?
                                                    PLAN_TO_INCREASED_PRICE_TRANSLATIONS[i18n.language]?.[planName]
                                                    ?? PLAN_TO_INCREASED_PRICE_TRANSLATIONS['en']?.[planName]
                                                    : PLAN_TO_PRICE_TRANSLATIONS[i18n.language]?.[planName]
                                                    ?? PLAN_TO_PRICE_TRANSLATIONS['en']?.[planName]
                                            }{t("mo")}
                                        </span>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className='payments-and-plan-change-buttons-block'>
                            <button onClick={handleCancelChangePlan} className='payments-and-plan-change-cancel'>{t("cancel")}</button>
                            <button onClick={handleChangePlan} disabled={selectedChangePlan === planName ? true : false} className='payments-and-plan-change-save'>
                                {t("saveChanges")}
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <h6 className='payments-and-plan-settings-subtitle'>{t("yourSubscription")}</h6>
                        <div className='payments-and-plan-subscription-status'>
                            <span className='subscription-status-title'>{t("subscriptionStatus")}</span>
                            <div className='subscription-status-block'>
                                <span className='subscription-status-green-dot'></span>
                                <span className='subscription-status-text'>
                                    {planName !== 'Free trial' || trialDaysLeft > 0 ? t("active") : t("inactive")}
                                </span>
                            </div>
                        </div>
                        <div className='subscription-current-plan-block'>
                            <div className='subscription-current-plan-left-side-block'>
                                <span className='subscription-current-plan-name'>{t(`${PLAN_NAME_TRANSLATIONS[planName] || 'Unlimited Plan'}`)}</span>
                                <span className='subscription-current-plan-price'>
                                    {
                                        PRICE_SIGN_TRANSLATIONS[i18n.language]
                                        ?? PRICE_SIGN_TRANSLATIONS['en']
                                    }
                                    {
                                        subscription?.increased_prices ?
                                            PLAN_TO_INCREASED_PRICE_TRANSLATIONS[i18n.language]?.[planName]
                                            ?? PLAN_TO_INCREASED_PRICE_TRANSLATIONS['en']?.[planName]
                                            : PLAN_TO_PRICE_TRANSLATIONS[i18n.language]?.[planName]
                                            ?? PLAN_TO_PRICE_TRANSLATIONS['en']?.[planName]
                                    } {planName !== '14-Days Limited Access' && planName !== '14-Days Full Access' && (
                                        <>
                                            {t("mo")}
                                        </>
                                    )}
                                </span>
                            </div>
                            <div className='subscription-current-plan-right-side-block'>
                                {planName !== 'Free trial' && !settings?.subscription_canceled && (
                                    <button onClick={handleCancelPlan} className='subscription-current-plan-change-button'>{t("cancelPlan")}</button>
                                )}
                                {settings?.subscription_canceled ? (
                                    <button onClick={handleReactivatePlan} className='subscription-current-plan-change-button'>
                                        {t("reactivate")}
                                    </button>
                                ) : (
                                    <button onClick={handleNavigateToUpgradePlan} className='subscription-current-plan-change-button'>
                                        {planName === 'Free trial' ? t("upgradePlan") : t("changePlan")}
                                    </button>
                                )}
                            </div>
                        </div>
                        {settings?.subscription_canceled ? (
                            <p className='subscription-current-auto-renews-text'>
                                {t("youHaveCanceledYourSubscription")}
                            </p>
                        ) : (
                            <>
                                {(planName === '14-Days Limited Access' || planName === '14-Days Full Access') && (
                                    <p className='subscription-current-auto-renews-text'>
                                        {
                                            subscription?.increased_prices
                                                ? t("14daysLimitedAccessNoteIncreased")
                                                : t("14daysLimitedAccessNote")
                                        }
                                    </p>
                                )}
                            </>
                        )}
                        {subscription?.payment_method === 'stripe' && (
                            <>
                                <span className='settings-tab-underline'></span>
                                <div className='settings-tab-payment-methods-header-block'>
                                    {addCardElement ? (
                                        <h6 className='settings-tab-payment-methods-title'>{t("addPaymentMethod")}</h6>
                                    ) : (
                                        <>
                                            <h6 className='settings-tab-payment-methods-title'>{t("paymentMethods")}</h6>
                                            <button onClick={handleAddPaymentMethod} className='settings-tab-payment-method-add-btn'>
                                                {t("addPaymentMethodDefault")}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                        {addCardElement ? (
                            <>
                                <Elements stripe={stripePromise}>
                                    <CreditCardForm handleCancelAddMethod={handleCancelAddMethod} />
                                </Elements>
                            </>
                        ) : (
                            <>
                                {subscription?.payment_method === 'stripe' && paymentMethods?.data?.map((method) => (
                                    <div key={method.id} className='settings-tab-payment-method-item-block'>
                                        <div className='settings-tab-payment-method-item-left-side'>
                                            <img className='settings-tab-payment-method-item-paypal-icon' src={MasterCard} alt="" />
                                            <div className='settings-tab-payment-method-item-information'>
                                                <span className='settings-tab-payment-method-item-information-name'>
                                                    MasterCard
                                                </span>
                                                <span className='settings-tab-payment-method-item-information-description'>
                                                    {t("endingIn")} {method.card.last4}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='settings-tab-payment-method-item-right-side'>
                                            {settings?.default_payment_method === method.id ? (
                                                <button disabled className='settings-tab-payment-method-default-btn'>
                                                    {t("default")}
                                                </button>
                                            ) : (
                                                <>
                                                    {planName !== 'Free trial' && (
                                                        <button
                                                            onClick={() => handleSetPaymentMethodAsDefault(method)}
                                                            className='settings-tab-payment-method-set-default-btn'
                                                        >
                                                            {t("setAsDefault")}
                                                        </button>
                                                    )}
                                                </>
                                            )}
                                            {settings?.default_payment_method !== method.id && (
                                                <img
                                                    onClick={() => handleDeletePaymentMethod(method)}
                                                    className='settings-tab-payment-method-action-icon'
                                                    src={Delete}
                                                    alt=""
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </>
                )}

                {userInvoices && !changePlan &&
                    <>
                        {userInvoices?.data &&
                            <>
                                <span className="settings-tab-underline"></span>
                                <h6 className='payments-and-plan-settings-subtitle'>
                                    {t("transactionHistory")}
                                </h6>
                                <div className='settings-tab-information-block gap-2 m-0'>
                                    <div className='flex justify-start items-center py-2 settings-invoice-details-none'>
                                        <p className='w-[20%] font-semibold text-[14px]'>{t("transactionDate")}</p>
                                        <p className='w-[20%] font-semibold text-[14px]'>{t("paymentDetails")}</p>
                                        <p className='w-[20%] font-semibold text-[14px]'>{t("status")}</p>
                                    </div>
                                    <span className="settings-tab-underline m-0 w-full  settings-invoice-details-none"></span>
                                    <div className='settings-invoices-block'>
                                        {userInvoices?.data &&
                                            <>
                                                {userInvoices?.data.map((invoice, index) => (
                                                    <div key={index} className='flex justify-between items-center py-2 settings-invoice-block'>
                                                        <div className='flex justify-start items-center w-[60%] settings-invoice-info'>
                                                            <p className='w-1/3 font-normal text-[14px] settings-invoice-header settings-invoice-bold'>{new Date(invoice.created * 1000).toLocaleString("en-US", {
                                                                timeZone: "UTC",
                                                                month: "short",
                                                                day: "2-digit",
                                                                year: "numeric"
                                                            })}
                                                            </p>
                                                            <p className='w-1/3 font-normal text-[14px] settings-invoice-details-none'>Stripe</p>
                                                            <div className='w-1/3 settings-invoice-header'>
                                                                <p className='font-semibold text-[#00A066] capitalize px-2 py-1 border-[1px] border-[#00A066] rounded-full w-fit text-[12px] flex items-center justify-center gap-1'>
                                                                    <span className='w-[6px] h-[6px] rounded-full bg-[#00A066]'></span>
                                                                    {invoice.status === 'paid' ? t("paid") : invoice.status}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <p className='w-full font-normal text-[14px] d-none settings-invoice-details'>Stripe</p>

                                                        <button className=' bg-[#9747FF] py-2 px-3 rounded-lg text-white text-[14px] font-semibold flex gap-[10px] items-center settings-invoice-button' onClick={() => {
                                                            window.open(invoice.invoice_pdf, '_blank');
                                                        }}>
                                                            <FiDownload className='text-[16px]' /> {t("downloadInvoice")}
                                                        </button>
                                                    </div>
                                                ))}
                                            </>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
                {showCancelPlanModal && !isMobile && (
                    <CancelPlanConfirmationModal
                        show={showCancelPlanModal}
                        onClose={() => setShowCancelPlanModal(false)}
                        cancelPlan={cancelPlan}
                        planName={planName}
                    />
                )}
                {showCancelPlanModal && isMobile && (
                    <CancelPlanConfirmationMobile
                        blockRef={cancelPlanMobileBlockRef}
                        setShowCancelPlanModal={setShowCancelPlanModal}
                        cancelPlan={cancelPlan}
                        planName={planName}
                    />
                )}
            </div>
        </div >
    )
}

export default PaymentAndPlanSettings