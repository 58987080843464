import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Link, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { POWERED_BY_STRIPE_IMAGES_TRANSLATIONS } from '../../helpers/image-translations';
import { useCustomLoggerMutation, useSendEmailSendgridUpdatesYourAccountMutation } from '../../api/api';
import { PRICE_SIGN_TRANSLATIONS } from '../../helpers/translation-constants';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import useAccountNewPlans from '../../hooks/useAccountNewPlans';
import { useAuthContext } from '../../context/AuthContext';
import useGenerateUrl from '../../hooks/useGenerateUrl';
import { accountPlans } from '../../helpers/constants';
import { useViewport } from '../../hooks/useViewport';
import i18n from '../../i18n/config';

import PayPalButton from '../PayPalButton/PayPalButton';

import checkoutCreditCardInactive from '../../assets/icons/checkout-creadit-card-inactive.svg'
import CheckoutCancelAnytimeGreen from '../../assets/icons/checkout-cancel-anytime-green.svg'
import CheckoutMoneyBackGreen from '../../assets/icons/checkout-money-back-green.svg'
import CheckoutDesktopBack from '../../assets/icons/checkout-desktop-back.svg'
import checkoutCreditCard from '../../assets/icons/cehckout-credit-card.svg'
import CheckoutStepActive from '../../assets/icons/checkout-step-active.svg'
import CheckoutStepDone from '../../assets/icons/checkout-step-done.svg'
import checkoutPaypal from '../../assets/icons/checkout-paypal.svg'

const StripeForm = ({ planName, priceId }) => {
    const subscription = useSelector((state) => state.app.subscription)
    const [loading, setLoading] = useState(false)
    const [planPrice, setPlanPrice] = useState(0)
    const [selectedPaymnetMethod, setSelectedPaymnetMethod] = useState('card')
    const [selectedPlan, setSelectedPlan] = useState({})
    const [isDisabled, setIsDisabled] = useState(true)
    const [searchParams] = useSearchParams()

    const [customLogger] = useCustomLoggerMutation()

    const { isMobile } = useViewport()
    const { accountNewPlans } = useAccountNewPlans()
    const { setExpandNavigation } = useAuthContext()
    const elements = useElements();
    const stripe = useStripe();

    const { t } = useTranslation()

    const navigate = useCustomNavigate()
    const generateUrl = useGenerateUrl()

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setLoading(true)
        if (searchParams.get('isNewUser') === 'true') {
            window.gtag('event', 'click_purchase_button', {
                type: 'new_user',
            })
        } else {
            window.gtag('event', 'click_purchase_button', {
                type: 'existing_user',
            })
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/upgrade-plan/success?planName=${planName}`,
            },
        })

        if (error) {
            navigate(`/upgrade-plan/error?priceId=${priceId}&planName=${planName}&isNewUser=${searchParams.get('isNewUser')}`)
        }
    };

    const paymentElementOptions = {
        layout: "tabs",
    }

    const handleNavigateToChangePlan = () => {
        navigate(`/upgrade-plan-new?planName=${planName}${searchParams.get('isNewUser') === 'true' ? '&icon=close' : ''}`)
    }

    const handleBackToPlans = () => {
        navigate(`/upgrade-plan-new?email=true${searchParams.get('isNewUser') === 'true' ? '&icon=close' : ''}`)
    }

    const onStripeFormChange = (event) => {
        if (event.complete) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }

    useEffect(() => {
        setExpandNavigation(false)
    }, [setExpandNavigation])

    useEffect(() => {
        if (planName) {
            setSelectedPlan(accountNewPlans.find((el) => el.shortName === planName))
        }
    }, [planName, accountNewPlans])

    useEffect(() => {
        customLogger({
            action: 'pageView',
            description: 'Checkout Page View'
        })
    }, [customLogger])

    useEffect(() => {
        let foundPlanPrice;

        if (subscription?.new_flow) {
            foundPlanPrice = accountNewPlans.find((el) => el.shortName === planName)?.yearlyPrice[i18n.language]
                ?? accountPlans.find((el) => el.shortName === planName)?.yearlyPrice['en']
        } else {
            foundPlanPrice = accountPlans.find((el) => el.shortName === planName)?.yearlyPrice
        }

        if (foundPlanPrice) {
            setPlanPrice(foundPlanPrice)
        }
    }, [planName, accountNewPlans, subscription])

    useEffect(() => {
        if (planName) {
            window.gtag('event', 'begin_checkout', {
                currency: "USD",
                value: accountPlans.find((el) => el.shortName === planName)?.yearlyPrice,
                items: [
                    {
                        item_id: accountPlans.find((el) => el.shortName === planName)?.price_id,
                        item_name: accountPlans.find((el) => el.shortName === planName)?.name,
                    }
                ]
            })
        }
    }, [planName])

    return (
        <form
            style={{
                paddingTop: '0px'
            }}
            onSubmit={handleSubmit}
            className="checkout-form w-full"
        >
            <div className="checkout-page pb-[60px] mobile:pb-4">
                <div className='flex items-center justify-between w-full max-w-[1264px] mt-[40px] mobile:hidden'>
                    <div className='flex items-center gap-[16px]'>
                        <div onClick={handleBackToPlans} className='min-w-[40px] h-[40px] rounded-[8px] border-solid border-[1px] border-[#E2E8F0] flex items-center justify-center cursor-pointer'>
                            <img src={CheckoutDesktopBack} alt="" />
                        </div>
                        <div className="checkout-body-header text-[#141315]">{t("secureCheckout")}</div>
                        <img
                            src={
                                POWERED_BY_STRIPE_IMAGES_TRANSLATIONS[i18n.language]
                                ?? POWERED_BY_STRIPE_IMAGES_TRANSLATIONS['en']
                            }
                            width={120}
                            alt=""
                        />
                    </div>
                    <div className='upgrade-plan-new-plan-header__steps'>
                        <div className='upgrade-plan-new-plan-header__step-item'>
                            <img src={CheckoutStepDone} alt="" />
                            <span className='upgrade-plan-new-plan-header__step-item-text'>
                                {t("qrCodeReady")}
                            </span>
                        </div>
                        <div className='upgrade-plan-new-plan-header__step-item'>
                            <img src={CheckoutStepDone} alt="" />
                            <span className='upgrade-plan-new-plan-header__step-item-text'>
                                {t("selectAPlan")}
                            </span>
                        </div>
                        <div className='upgrade-plan-new-plan-header__step-item-active'>
                            <img src={CheckoutStepActive} alt="" />
                            <span className='upgrade-plan-new-plan-header__step-item-text'>
                                {t("completed")}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="checkout-body">
                    <div className="checkout-field-block stripe-shadow-blocks">
                        <div className="checkout-card-field pt-0">
                            <span className="checkout-new-payment-method-title">
                                {t("paymentMethod")}
                            </span>
                            <div className='select-payments-block-wrapper'>
                                <div onClick={() => setSelectedPaymnetMethod('card')} className={`selecet-payment-method-element ${selectedPaymnetMethod === 'card' ? 'active' : ''}`}>
                                    {selectedPaymnetMethod === 'card' ? (
                                        <img src={checkoutCreditCard} alt="" />
                                    ) : (
                                        <img src={checkoutCreditCardInactive} alt="" />
                                    )}
                                    <span className='select-payment-method-name'>{t("creditCard")}</span>
                                </div>
                                <div onClick={() => setSelectedPaymnetMethod('paypal')} className={`selecet-payment-method-element ${selectedPaymnetMethod === 'paypal' ? 'active' : ''}`}>
                                    <img src={checkoutPaypal} alt="" />
                                    <span className='select-payment-method-name'>PayPal</span>
                                </div>
                            </div>
                        </div>
                        <div className='payment-element-wrapper'>
                            {selectedPaymnetMethod === 'card' ? (
                                <PaymentElement onChange={onStripeFormChange} id="payment-element" options={paymentElementOptions} />
                            ) : (
                                <div className='payment-paypal-block'>
                                    <img width={36} src={checkoutPaypal} alt="" />
                                    <span className='payment-paypal-block-text'>{t("clickBelowToPayThroughPaypal")}</span>
                                </div>
                            )}
                        </div>
                        <div className='checkout-new-selected-plan-block'>
                            <div className='change-selected-plan-block'>
                                <div className='change-selected-plan-block-info'>
                                    <span className='change-selected-plan-block-title'>{t("selectedPlan")}</span>
                                    <span className='change-selected-plan-block-name'>{selectedPlan?.name}</span>
                                </div>
                                <span onClick={handleNavigateToChangePlan} className='change-selected-plan-block-change-btn'>
                                    {t("changePlan")}
                                </span>
                            </div>
                            <div className='checkout-new-due-total-block'>
                                <span className='checkout-new-due-total-title'>{t("dueToday")}</span>
                                <span className='checkout-new-due-total-price'>
                                    {PRICE_SIGN_TRANSLATIONS[i18n.language] ?? '$'}{planPrice}
                                </span>
                            </div>
                        </div>
                        <div className='stripe-purchase-button'>
                            {subscription?.new_flow && (
                                <span className='new-flow-checkout-text'>
                                    {t("byClickingPurchaseYouAgreeToBeCharged")} {PRICE_SIGN_TRANSLATIONS[i18n.language] ?? '$'}{planPrice} {t("nowAndAcceptOur")} <Link className='new-flow-checkout-text-link' to={generateUrl('/terms-of-service')}>{t("termsOfUse")}</Link> {t("and")} <Link className='new-flow-checkout-text-link' to={generateUrl("/privacy-policy")}>{t("privacyPolicyWithDot")}</Link> {t("yourPaymentWillAppearAsQRCodeDeveloperOnYourCard")} {planName !== 'Yearly' ? (
                                        <>
                                            {subscription?.increased_prices ? t("after14daysYouWillBeBilledEveryMonthIncreased") : t("after14daysYouWillBeBilledEveryMonth")}
                                        </>
                                    ) : ""} {t("youCanCancelAnytime")}
                                </span>
                            )}
                            {selectedPaymnetMethod === 'card' ? (
                                <button
                                    id="submit"
                                    disabled={loading || isDisabled}
                                    className="checkout-button relative"
                                >
                                    {loading && (
                                        <svg style={{ position: 'absolute', left: 'calc(50% - 100px)' }} className='stripe-loader' width={25} height={25} fill="#9747FF" viewBox="25 25 50 50">
                                            <circle r="20" cy="50" cx="50"></circle>
                                        </svg>
                                    )}
                                    {t("purchase")}
                                </button>
                            ) : (
                                <>
                                    <PayPalButton price={selectedPlan.yearlyPrice} priceId={priceId} planName={planName} selectedPlan={selectedPlan} />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="checkout-body-right border-solid border-[1px] border-[#E2E8F0] stripe-shadow-blocks">
                        <div className='checkout-guarantee-block disable-on-desktop'>
                            <span style={{ color: '#7D8898' }} className='new-flow-checkout-text'>{t("byClickingPurchaseYouAgreeToBeCharged")} {PRICE_SIGN_TRANSLATIONS[i18n.language] ?? '$'}{planPrice} {t("nowAndAcceptOur")} <Link className='new-flow-checkout-text-link' to={generateUrl('/terms-of-service')}>{t("termsOfUse")}</Link> {t("and")} <Link className='new-flow-checkout-text-link' to={generateUrl("/privacy-policy")}>{t("privacyPolicyWithDot")}</Link> {t("yourPaymentWillAppearAsQRCodeDeveloperOnYourCard")} {planName !== 'Yearly' ? (
                                <>
                                    {subscription?.increased_prices ? t("after14daysYouWillBeBilledEveryMonthIncreased") : t("after14daysYouWillBeBilledEveryMonth")}
                                </>
                            ) : ""} {t("youCanCancelAnytime")} </span>
                        </div>
                        <div className='checkout-guarantee-underline disable-on-desktop'></div>
                        <div className='checkout-guarantee-block'>
                            <div className='checkout-guarantee-icon-wrapper border-0'>
                                <img src={CheckoutMoneyBackGreen} alt="" />
                            </div>
                            <span className='checkout-guarantee-block-title'>{t("moneyBackGuarantee")}</span>
                            <span className='checkout-guarantee-block-text'>{t("ifYouAreNotFullySatisfiedWithinThe14day")}</span>
                        </div>
                        <div className='checkout-guarantee-underline'></div>
                        <div className='checkout-guarantee-block'>
                            <div className='checkout-guarantee-icon-wrapper border-0'>
                                <img src={CheckoutCancelAnytimeGreen} alt="" />
                            </div>
                            <span className='checkout-guarantee-block-title'>{t("cancelViaYOurOnlineAccount")}</span>
                            <span className='checkout-guarantee-block-text'>{t("youMayCancelAnytimeFromDashboard")}</span>
                        </div>
                        <div style={{ marginBottom: '116px' }} className='checkout-guarantee-underline disable-on-desktop'></div>
                    </div>
                    <div className='change-selected-plan-block-mobile'>
                        <div className='change-selected-plan-block-info'>
                            <span className='change-selected-plan-block-title'>{t("selectedPlan")}</span>
                            <span className='change-selected-plan-block-name'>{selectedPlan?.name}</span>
                        </div>
                        <span onClick={handleNavigateToChangePlan} className='change-selected-plan-block-change-btn'>
                            {t("changePlan")}
                        </span>
                    </div>
                    <div className='checkout-new-purchase-block'>
                        <div className='checkout-new-due-total-block w-full'>
                            <span className='checkout-new-due-total-title'>{t("dueToday")}</span>
                            <span className='checkout-new-due-total-price'>
                                {PRICE_SIGN_TRANSLATIONS[i18n.language] ?? '$'}{planPrice}
                            </span>
                        </div>
                        {selectedPaymnetMethod === 'card' ? (
                            <button
                                id="submit"
                                disabled={loading || isDisabled}
                                className="checkout-new-purchase-button relative disabled:opacity-30"
                            >
                                {loading && (
                                    <svg style={{ position: 'absolute', left: 'calc(50% - 100px)' }} className='stripe-loader' width={25} height={25} fill="#9747FF" viewBox="25 25 50 50">
                                        <circle r="20" cy="50" cx="50"></circle>
                                    </svg>
                                )}
                                {t("purchase")}
                            </button>
                        ) : (
                            <>
                                <PayPalButton height={56} price={selectedPlan.yearlyPrice} priceId={priceId} planName={planName} selectedPlan={selectedPlan} />
                            </>
                        )}
                    </div>
                </div>
            </div>
            {searchParams.get('isNewUser') !== 'true' && !isMobile && (
                <div className="upgrade-plan-navigation-line">
                    <div className='upgrade-plan-navigation-line-top'>
                        <div className='upgrade-plan-navigation-line-top-inside'></div>
                    </div>
                    <div className='upgrade-plan-navigation-line-bottom'>
                        <div className='upgrade-plan-navigation-line-bottom-inside'></div>
                    </div>
                </div>
            )}
        </form>
    )
}

export default StripeForm