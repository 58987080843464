import { pt, enUS, de, es, fr, da, it, hu } from 'date-fns/locale'

// Language translations keys for the Firebase and Stripe
export const STRIPE_FIREBASE_EMAIL_LANGUAGE_TRANSLATIONS_KEYS = {
    'en': 'en',
    'pt': 'pt',
    'de': 'de',
    'es': 'es',
    'fr': 'fr',
    'dk': 'da',
    'it': 'it',
    'hu': 'hu',
    'mx': 'es',
    'portugal': 'pt',
}

// Language name to key translations
export const LANGUAGE_NAME_TO_KEY_TRANSLATIONS = {
    'English': 'english',
    'Portuguese': 'portuguese',
    'German': 'german',
    'Spanish': 'spanish',
    'French': 'french',
    'Danish': 'danish',
    'Italian': 'italian',
    'Hungarian': 'hungarian',
    'Mexican': 'mexican',
    'Portugal': 'portugal',
}

// Guides navigation translations link
export const GUIDES_NAVIGATION_TRANSLATIONS = {
    'en': '/guides',
    'pt': '/pt/guias',
    'de': '/de/anleitungen',
    'es': '/es/guias',
    'fr': '/fr/guides',
    'dk': '/dk/guider',
    'it': '/it/guide',
    'hu': '/hu/utmutatok',
    'mx': '/es/guias',
    'portugal': '/pt/guias',
}

// Date Range format translations
export const LANGUAGE_DATE_RANGE_FORMAT = {
    'en': enUS,
    'pt': pt,
    'de': de,
    'es': es,
    'fr': fr,
    'dk': da,
    'it': it,
    'hu': hu,
    'mx': es,
    'portugal': pt,
}

// Language short version to currency code
export const LANGUAGE_SHORT_VERSION_TO_CURRENCY_CODE = {
    'en': 'USD',
    'pt': 'BRL',
    'de': 'EUR',
    'es': 'EUR',
    'fr': 'EUR',
    'dk': 'DKK',
    'it': 'EUR',
    'hu': 'HUF',
    'mx': 'MXN',
    'portugal': 'EUR',
}

// This is the conversion from the short version of the language name to the full version of the language
export const SHORT_VERSION_TO_FULL_VERSION_OF_LANGUAGE = {
    'en': 'English',
    'pt': 'Portuguese',
    'de': 'German',
    'es': 'Spanish',
    'fr': 'French',
    'dk': 'Danish',
    'it': 'Italian',
    'hu': 'Hungarian',
    'mx': 'Mexican',
    'portugal': 'Portugal',
}

// This is the conversion from the full version of the language to the short version of the language name
export const FULL_VERSION_TO_SHORT_VERSION_OF_LANGUAGE = {
    'English': 'en',
    'Portuguese': 'pt',
    'German': 'de',
    'Spanish': 'es',
    'French': 'fr',
    'Danish': 'dk',
    'Italian': 'it',
    'Hungarian': 'hu',
    'Mexican': 'mx',
    'Portugal': 'portugal',
}

// This is the translations for the change plan Stripe and PayPal prices based on the language
export const TRANSLATIONS_PRICES_FOR_CHANGE_PLAN = {
    'en': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-2PD155281Y245623PMZLNFZA',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-0CF06977830134921MVUYKKA',
            plan_name: 'Yearly Plan',
        }
    },
    'pt': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-0LA79173GA657594VMZLNGJQ',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-8SU09002P7245240LMZLM27I',
            plan_name: 'Yearly Plan',
        }
    },
    'de': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-0JK17181J6235622LM25RHXI',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-10068147TW535905MM25RLGI',
            plan_name: 'Yearly Plan',
        }
    },
    'es': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-0JK17181J6235622LM25RHXI',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-10068147TW535905MM25RLGI',
            plan_name: 'Yearly Plan',
        }
    },
    'fr': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-0JK17181J6235622LM25RHXI',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-10068147TW535905MM25RLGI',
            plan_name: 'Yearly Plan',
        }
    },
    'dk': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-0874093121496891GM3MAAUQ',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-0XY325601C5545827M3L7YWQ',
            plan_name: 'Yearly Plan',
        }
    },
    'it': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-0JK17181J6235622LM25RHXI',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-10068147TW535905MM25RLGI',
            plan_name: 'Yearly Plan',
        }
    },
    'hu': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-5JK96774LV3922111M3MABLQ',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-8SV64754VR749811MM3L7X3A',
            plan_name: 'Yearly Plan',
        }
    },
    'mx': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-9G096934B35255449M4JBYAI',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-0WV84734TP085551YM4JB6OA',
            plan_name: 'Yearly Plan',
        }
    },
    'portugal': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-0JK17181J6235622LM25RHXI',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-10068147TW535905MM25RLGI',
            plan_name: 'Yearly Plan',
        }
    },
}

// This is the translations for the change plan Stripe and PayPal increased prices based on the language
export const TRANSLATIONS_INCREASED_PRICES_FOR_CHANGE_PLAN = {
    'en': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QUOBEG2gC76wG4CJpMnyCsc',
            price_id_paypal: 'P-9H6861110Y607014RM5L7Q6A',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QUOBEG2gC76wG4CTeVYSISL',
            price_id_paypal: 'P-66889183P0087261DM5L7QRA',
            plan_name: 'Yearly Plan',
        }
    },
    'pt': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-0LA79173GA657594VMZLNGJQ',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-8SU09002P7245240LMZLM27I',
            plan_name: 'Yearly Plan',
        }
    },
    'de': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-0JK17181J6235622LM25RHXI',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-10068147TW535905MM25RLGI',
            plan_name: 'Yearly Plan',
        }
    },
    'es': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-0JK17181J6235622LM25RHXI',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-10068147TW535905MM25RLGI',
            plan_name: 'Yearly Plan',
        }
    },
    'fr': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-0JK17181J6235622LM25RHXI',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-10068147TW535905MM25RLGI',
            plan_name: 'Yearly Plan',
        }
    },
    'dk': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-0874093121496891GM3MAAUQ',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-0XY325601C5545827M3L7YWQ',
            plan_name: 'Yearly Plan',
        }
    },
    'it': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-0JK17181J6235622LM25RHXI',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-10068147TW535905MM25RLGI',
            plan_name: 'Yearly Plan',
        }
    },
    'hu': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-5JK96774LV3922111M3MABLQ',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-8SV64754VR749811MM3L7X3A',
            plan_name: 'Yearly Plan',
        }
    },
    'mx': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-9G096934B35255449M4JBYAI',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-0WV84734TP085551YM4JB6OA',
            plan_name: 'Yearly Plan',
        }
    },
    'portugal': {
        'Unlimited Access': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CKjRXK9RN',
            price_id_paypal: 'P-0JK17181J6235622LM25RHXI',
            plan_name: 'Unlimited Access',
        },
        'Yearly Plan': {
            price_id_stripe: 'price_1QBBKeG2gC76wG4CwQuUHux0',
            price_id_paypal: 'P-10068147TW535905MM25RLGI',
            plan_name: 'Yearly Plan',
        }
    },
}

// Plan to price conversion based on the translations language
export const PLAN_TO_PRICE_TRANSLATIONS = {
    'en': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 1.45,
        '14-Days Full Access': 1.95,
        'Yearly Plan': 14.95,
        'Unlimited Plan': 28.95,
        'Unlimited Access': 28.95,
    },
    'pt': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 6.95,
        '14-Days Full Access': 9.95,
        'Yearly Plan': 77.10,
        'Unlimited Plan': 149.95,
        'Unlimited Access': 149.95,
    },
    'de': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 1.45,
        '14-Days Full Access': 1.95,
        'Yearly Plan': 14.95,
        'Unlimited Plan': 28.95,
        'Unlimited Access': 28.95,
    },
    'es': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 1.45,
        '14-Days Full Access': 1.95,
        'Yearly Plan': 14.95,
        'Unlimited Plan': 28.95,
        'Unlimited Access': 28.95,
    },
    'fr': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 1.45,
        '14-Days Full Access': 1.95,
        'Yearly Plan': 14.95,
        'Unlimited Plan': 28.95,
        'Unlimited Access': 28.95,
    },
    'dk': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 9.95,
        '14-Days Full Access': 13.95,
        'Yearly Plan': 99.95,
        'Unlimited Plan': 199.95,
        'Unlimited Access': 199.95,
    },
    'it': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 1.45,
        '14-Days Full Access': 1.95,
        'Yearly Plan': 14.95,
        'Unlimited Plan': 28.95,
        'Unlimited Access': 28.95,
    },
    'hu': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 499,
        '14-Days Full Access': 699,
        'Yearly Plan': 5499,
        'Unlimited Plan': 10699,
        'Unlimited Access': 10699,
    },
    'mx': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 29.95,
        '14-Days Full Access': 39.95,
        'Yearly Plan': 291,
        'Unlimited Plan': 595.95,
        'Unlimited Access': 595.95,
    },
    'portugal': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 1.45,
        '14-Days Full Access': 1.95,
        'Yearly Plan': 14.95,
        'Unlimited Plan': 28.95,
        'Unlimited Access': 28.95,
    },
}

// Plan to increased price conversion based on the translations language
export const PLAN_TO_INCREASED_PRICE_TRANSLATIONS = {
    'en': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 1.45,
        '14-Days Full Access': 1.95,
        'Yearly Plan': 19,
        'Unlimited Plan': 38.95,
        'Unlimited Access': 38.95,
    },
    'pt': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 6.95,
        '14-Days Full Access': 9.95,
        'Yearly Plan': 77.10,
        'Unlimited Plan': 149.95,
        'Unlimited Access': 149.95,
    },
    'de': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 1.45,
        '14-Days Full Access': 1.95,
        'Yearly Plan': 14.95,
        'Unlimited Plan': 28.95,
        'Unlimited Access': 28.95,
    },
    'es': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 1.45,
        '14-Days Full Access': 1.95,
        'Yearly Plan': 14.95,
        'Unlimited Plan': 28.95,
        'Unlimited Access': 28.95,
    },
    'fr': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 1.45,
        '14-Days Full Access': 1.95,
        'Yearly Plan': 14.95,
        'Unlimited Plan': 28.95,
        'Unlimited Access': 28.95,
    },
    'dk': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 9.95,
        '14-Days Full Access': 13.95,
        'Yearly Plan': 99.95,
        'Unlimited Plan': 199.95,
        'Unlimited Access': 199.95,
    },
    'it': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 1.45,
        '14-Days Full Access': 1.95,
        'Yearly Plan': 14.95,
        'Unlimited Plan': 28.95,
        'Unlimited Access': 28.95,
    },
    'hu': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 499,
        '14-Days Full Access': 699,
        'Yearly Plan': 5499,
        'Unlimited Plan': 10699,
        'Unlimited Access': 10699,
    },
    'mx': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 29.95,
        '14-Days Full Access': 39.95,
        'Yearly Plan': 291,
        'Unlimited Plan': 595.95,
        'Unlimited Access': 595.95,
    },
    'portugal': {
        'Free trial': 0,
        'Starter Plan': 9.99,
        'Advanced Plan': 14.99,
        'Professional Plan': 45.99,
        '14-Days Limited Access': 1.45,
        '14-Days Full Access': 1.95,
        'Yearly Plan': 14.95,
        'Unlimited Plan': 28.95,
        'Unlimited Access': 28.95,
    },
}

// Price sign based on the language
export const PRICE_SIGN_TRANSLATIONS = {
    'en': '$',
    'pt': 'R$',
    'de': '€',
    'es': '€',
    'fr': '€',
    'dk': 'kr',
    'it': '€',
    'hu': 'Ft',
    'mx': 'M$',
    'portugal': '€',
}

// This is Yearly Plan prices based on the language for the Payments and Plans tab
export const YEARLY_PLAN_PRICES_TRANSLATIONS = {
    'en': '$14.95',
    'pt': 'R$ 77.10',
    'de': '€14.95',
    'es': '€14.95',
    'fr': '€14.95',
    'dk': 'kr 99.95',
    'it': '€14.95',
    'hu': 'Ft5499',
    'mx': 'M$291',
    'portugal': '€14.95',
}

// This is Yearly Plan increased prices based on the language for the Payments and Plans tab
export const YEARLY_PLAN_INCREASED_PRICES_TRANSLATIONS = {
    'en': '$19',
    'pt': 'R$ 77.10',
    'de': '€14.95',
    'es': '€14.95',
    'fr': '€14.95',
    'dk': 'kr 99.95',
    'it': '€14.95',
    'hu': 'Ft5499',
    'mx': 'M$291',
    'portugal': '€14.95',
}

// This is Unlimited Plan prices based on the language for the Payments and Plans tab
export const UNLIMITED_PLAN_PRICES_TRANSLATIONS = {
    'en': '$28.95',
    'pt': 'R$ 149.95',
    'de': '€28.95',
    'es': '€28.95',
    'fr': '€28.95',
    'dk': 'kr 199.95',
    'it': '€28.95',
    'hu': 'Ft10699',
    'mx': 'M$595.95',
    'portugal': '€28.95',
}

// This is Unlimited Plan increased prices based on the language for the Payments and Plans tab
export const UNLIMITED_PLAN_INCREASED_PRICES_TRANSLATIONS = {
    'en': '$38.95',
    'pt': 'R$ 149.95',
    'de': '€28.95',
    'es': '€28.95',
    'fr': '€28.95',
    'dk': 'kr 199.95',
    'it': '€28.95',
    'hu': 'Ft10699',
    'mx': 'M$595.95',
    'portugal': '€28.95',
}

// This is PayPal translation codes for the language that we have translations for
export const PAYPAL_TRANSLATION_CODES = {
    'en': 'en_US',
    'pt': 'pt_PT',
    'de': 'de_DE',
    'es': 'es_ES',
    'fr': 'fr_FR',
    'dk': 'da_DK',
    'it': 'it_IT',
    'hu': 'hu_HU',
    'mx': 'es_MX',
    'portugal': 'pt_PT',
}

// This is the list of languages that we have translations for
export const TRANSLATIONS_LANGUAGES = ['en', 'pt', 'de', 'es', 'fr', 'dk', 'it', 'hu', 'mx', 'portugal']

// This is the list of languages that we have translations for, it needs for checking if the language exists in the translations
export const EXISTING_TRANSLATIONS = {
    'en': 'en', // English
    'pt': 'pt', // Portuguese
    'de': 'de', // German
    'es': 'es', // Spanish
    'fr': 'fr', // French
    'dk': 'dk', // Danish
    'it': 'it', // Italian
    'hu': 'hu', // Hungarian
    'mx': 'mx', // Mexican Spanish
    'portugal': 'portugal', // Portugal
}

// Active tab translations keywords for the dashboard
export const ACTIVE_TAB_TRANSLATIONS = {
    'My Codes': 'myCodes',
    'Archived': "archived",
}

// View translations keywords for the dashboard
export const VIEW_TRANSLATIONS = {
    'Grid View': 'gridView',
    'List View': 'listView',
}

// Sort values translations keywords for the dashboard
export const DASHBOARD_SORT_VALUES_TRANSLATIONS = {
    'Newest': 'newest',
    'Dynamic': 'dynamic',
    'Static': 'static',
    'Oldest': 'oldest',
    'Edited': 'edited',
    'A-Z': 'A-Z',
    'Z-A': 'Z-A',
}

// User QR types translations keywords
export const USER_QR_TYPE_TRANSLATIONS = {
    'URL': 'URL',
    'Text': 'text',
    'Wi-Fi': 'Wi-Fi',
    'PDF': 'PDF',
    'MP3': 'MP3',
    'Video': 'video',
    'Image': 'image',
    'Website': 'website'
}

// Analytics filter block to label translations for Analytics page
export const ANALYTICS_FILTER_BLOCK_TO_LABEL_TRANSLATIONS = {
    'Operating System': 'operatingSystem',
    'Browser': 'browser',
    'Country': 'country',
    'Language': 'language',
    'City': 'city'
}

// Account deletion reasons translations keywords
export const ACCOUNT_DELETION_REASONS_TRANSLATIONS = {
    'Found an alternative service': 'foundAnAlternativeService',
    'Too expensive': 'tooExpensive',
    'Limited customization options': 'limitedCustomizationOptions',
    'I didn’t use it enough': 'iDidNotUseItEnough',
    'Limited features': 'limitedFeatures',
    'Other (please specify)': 'otherPleaseSpecify'
}

// FAQ types translations keywords
export const FAQ_TYPES_TRANSLATIONS = {
    "Quick Start": "quickStart",
    "Creating": "creating",
    "Printing": "printing"
}

// Plan names translations keywords
export const PLAN_NAME_TRANSLATIONS = {
    "Free trial": "freeTrial",
    "Starter Plan": "starterPlan",
    "Advanced Plan": "advancedPlan",
    "Professional Plan": "professionalPlan",
    "Limited Access": "limitedAccess",
    "Full Access": "fullAccess",
    "Yearly Plan": "yearlyPlan",
    "Unlimited Access": "unlimitedAccess",
    "Unlimited Plan": "unlimitedAccess",
    "14-Days Limited Access": "14daysLimitedAccess",
    "14-Days Full Access": "14daysFullAccess",
}

// Firebase errors translations keywords
export const FIREBASE_ERRORS_TRANSLATIONS = {
    "auth/email-already-in-use": "emailAlreadyInUse",
    "auth/invalid-email": "invalidEmail",
    "auth/operation-not-allowed": "operationNotAllowed",
    "auth/weak-password": "weakPassword",
    "auth/user-disabled": "userDisabled",
    "auth/user-not-found": "userNotFound",
    "auth/wrong-password": "wrongPassword",
}