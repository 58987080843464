import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import React from 'react'

import {
    useChangeUserPlanMutation,
    useSendEmailSendgridCancelPlanMutation,
    useUpdateDuringCancellationMutation
} from '../../api/api'
import {
    TRANSLATIONS_INCREASED_PRICES_FOR_CHANGE_PLAN,
    TRANSLATIONS_PRICES_FOR_CHANGE_PLAN
} from '../../helpers/translation-constants'
import i18n from '../../i18n/config'

const CancelPlanConfirmationMobile = ({ setShowCancelPlanModal, cancelPlan, blockRef, planName }) => {
    const user = useSelector((state) => state.app.user)
    const subscription = useSelector((state) => state.app.subscription)

    const { t } = useTranslation()

    const [sendEmailSendgridCancelPlan] = useSendEmailSendgridCancelPlanMutation()
    const [updateDuringCancellation] = useUpdateDuringCancellationMutation()
    const [changeUserPlan] = useChangeUserPlanMutation()

    const offer50PercentsDiscount = planName === 'Unlimited Plan'
        || planName === '14-Days Limited Access'
        || planName === '14-Days Full Access'

    const handleUpgradeToYearlyWithDiscount = () => {
        const priceData = subscription?.increased_prices ?
            TRANSLATIONS_INCREASED_PRICES_FOR_CHANGE_PLAN[i18n.language]?.['Yearly Plan']
            ?? TRANSLATIONS_INCREASED_PRICES_FOR_CHANGE_PLAN['en']?.['Yearly Plan']
            : TRANSLATIONS_PRICES_FOR_CHANGE_PLAN[i18n.language]?.['Yearly Plan']
            ?? TRANSLATIONS_PRICES_FOR_CHANGE_PLAN['en']?.['Yearly Plan']

        changeUserPlan({
            ...priceData
        })
        updateDuringCancellation()
        setShowCancelPlanModal(false)
    }

    const handleCancelPlan = () => {
        cancelPlan()
        sendEmailSendgridCancelPlan({
            plan_name: planName,
        })
        window.gtag('event', 'user_cancel_plan_from_settings', {
            user_id: user.uid,
            payment_method: subscription?.payment_method === 'paypal' ? 'paypal' : 'stripe',
        })
        setShowCancelPlanModal(false)
    }

    return (
        <div style={{ zIndex: '101' }} className='link-filter-background analytics-mobile-settings'>
            <div ref={blockRef} className='link-filter-block'>
                <div onClick={() => setShowCancelPlanModal(false)} className='mobile-tray-horizontal-bar'>
                    <div className='mobile-tray-horizontal-bar-element'></div>
                </div>
                {offer50PercentsDiscount ? (
                    <>
                        <h5 className='confirm-modal-title w-full mb-[24px]'>
                            {t("manageYourSubscription")}
                        </h5>
                        <div className='grid grid-cols-1 gap-2'>
                            <div className="cancel-plan-upgrade-to-yearly-block">
                                <div className="flex flex-col gap-[12px]">
                                    <h6 className="text-white text-base font-semibold text-center">
                                        {t("upgradeToYearlyPlan")}
                                    </h6>
                                    <h5 className="font-bold text-white text-[33px] leading-[40px] tracking-[-0.02em] text-center mobile:text-[28px] mobile:leading-[34px] mobile:tracking-[-0.02em]">
                                        {t("save50Percents")}
                                    </h5>
                                    <p className="text-base text-white text-center">
                                        {t("maintainAccessToAllOfYourCodesAndAllFeatures")}
                                    </p>
                                </div>
                                <button onClick={handleUpgradeToYearlyWithDiscount} className="h-[44px] rounded-lg bg-white text-[14px] leading-[17px] font-semibold text-[#141315] hover:bg-[#f7fafe] transition-colors duration-300">
                                    {
                                        subscription?.increased_prices
                                            ? t("upgradeToHalfYearlyPlanIncreased")
                                            : t("upgradeToHalfYearlyPlan")
                                    }
                                </button>
                            </div>
                            <div className='cancel-plan-confirm-block'>
                                <div className="flex flex-col gap-[12px] justify-center">
                                    <h6 className="text-base font-semibold text-[#141315] text-center">
                                        {t("cancelSubscription")}
                                    </h6>
                                    <p className="text-base text-[#141315] text-center">
                                        {t("onceCancelledYouWillLoseTheAbilityToCreateAndManageDynamicCodes")}
                                    </p>
                                </div>
                                <button onClick={handleCancelPlan} className="h-[44px] rounded-lg border-[1px] border-solid border-[#141315] text-[14px] leading-[17px] font-semibold text-[#141315] hover:border-[#E2E8F0] transition-colors duration-300">
                                    {t("cancelSubscription")}
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <h5 className='confirm-modal-title w-full mb-3'>{t("areYouSureYouWantToCancelYourPlan")}</h5>
                        <div className='confirm-modal-actions-block flex-col-reverse'>
                            <button onClick={() => setShowCancelPlanModal(false)} className='confirm-modal-action-cancel'>{t("keepPlan")}</button>
                            <button onClick={handleCancelPlan} className='confirm-modal-action-delete'>{t("yesCancelPlan")}</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default CancelPlanConfirmationMobile