import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const useReturnFaqQuestions = () => {
    const subscription = useSelector((state) => state.app.subscription)

    const { t } = useTranslation()

    const FAQ_QUESTION_LIST = {
        'Quick Start': [
            {
                id: 1,
                question: t("howDoesAQRCodeGeneratorWork"),
                answer: t("aQRCodeGeneratorTurnsInformationLikeWebsiteLinksOrContactDetails"),
            },
            {
                id: 2,
                question: t("whoCanCreateAQRCode"),
                answer: t("qrCodeDeveloperSimplifiesTheGenerationProcess")
            },
            {
                id: 3,
                question: t("canIUseMyQRCodeForCommercialPurposes"),
                answer: t("absolutelyQRCodeDeveloperImposesNoLimitationsOnQRCodeUsage"),
            },
            {
                id: 4,
                question: t("whatKindOfInformationCanBeStoredOnAQRCode"),
                answer: t("qrCodesCanHoldAWideRangeOfDigitalContent"),
            },
            {
                id: 5,
                question: t("areQRCodesFree"),
                answer: t("qrCodeDeveloperOffersUsersAFreeTrialPeriod"),
            },
            {
                id: 6,
                question: subscription?.increased_prices ? t("whyWasICharged2895Increased") : t("whyWasICharged2895"),
                answer: t("uponSigningUpYouAreAutomaticallyOptedInForARecurringSubscription"),
            }
        ],
        'Creating': [
            {
                id: 1,
                question: t("howDoDynamicCodesDifferFromStaticCodes"),
                answer: t("dynamicQRCodeAreMoreVersatileThanStaticOnesBecauseYouCanUpdateTheirContentAnytime"),
            },
            {
                id: 2,
                question: t("canICustomizeMyQRCode"),
                answer: t("withQRCodeDeveloperYouCanCustomizeYourQRCodesAppearanceByIncorporatingFrames"),
            },
            {
                id: 3,
                question: t("isThereALimitToHowManyCodesICanGenerate"),
                answer: t("dependingOnYourChosenPlanThereMayBeRestrictionsOnTheNumberOfQRCodes"),
            },
            {
                id: 4,
                question: t("whatFormatsAreAvailableForDownloadingAQRCode"),
                answer: t("qrCodesCanBeGeneratedAsPngJpgOrSvgFiles"),
            },
        ],
        'Printing': [
            {
                id: 1,
                question: t("howToScanAQRCode"),
                answer: [
                    t("scanningAQRCodeIsEasyFollowTheseSteps"),
                    t("pointYourSmartphoneCameraAtTheQrCode"),
                    t("makeSureTheQRCodeIsWithinTheCameraFrame"),
                    t("yourDeviceShouldAutomaticallyRecognizeTheQRCodeAndDisplayNotification"),
                    t("tapTheNotificationOrFollowTheOnScreenInstructionsToAccessContent")
                ],
            },
            {
                id: 2,
                question: t("whatIsTheSmallestQRCodeSizeForPrint"),
                answer: t("ensureYourQRCodeIsAtLeast1cm"),
            },
            {
                id: 3,
                question: t("whatIsTheBestFormatForPrintingAQRCode"),
                answer: t("forOptimalPrintedResultsExportYourCodeInSvgFormat"),
            },
            {
                id: 4,
                question: t("whatIsTheBestFormatForWebBasedQrCodes"),
                answer: t("forWebBasedQRCodesSuchAsThoseInEmailsOrOnWebsites"),
            },
            {
                id: 5,
                question: t("canPeopleScanAQRCodeOnAWebsite"),
                answer: t("qrCodesAreVersatileAndCanBeScannedOnNearlyAnyDigitalMedium"),
            },
            {
                id: 6,
                question: t("canYouTrackQRCodeScans"),
                answer: t("absolutelyWithADynamicQRCodeYouGainAccessToAdvanced"),
            }
        ]
    }

    return { FAQ_QUESTION_LIST }
}

export default useReturnFaqQuestions