import { MdOutlineFileDownload } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { FaRegFile } from 'react-icons/fa'
import React from 'react'

import { USER_CREATE_CODE_IMAGES_TRANSLATIONS } from '../../helpers/image-translations'
import i18n from '../../i18n/config'

import EmptyImage from '../../assets/icons/code-create/empty-image.svg'
import EmptyLogo from '../../assets/icons/code-create/empty-logo.svg'

const PdfPreview = ({ data, isContentAdded = true, version = 1 }) => {
    const { t } = useTranslation()

    const decreaseElementsSize = window.location.pathname.includes('/code') ? false : true

    const handleOpenFiles = (files) => {
        if (files && files.length > 0) {
            files.forEach(async (file) => {
                const response = await fetch(file.file);
                const blob = await response.blob();

                const decodedBlob = new Blob([blob], { type: 'application/pdf' });

                const url = URL.createObjectURL(decodedBlob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'document.pdf';
                link.click();

                window.URL.revokeObjectURL(url);
            })
        }
    }

    return (
        <>
            {isContentAdded ? (
                <>
                    {version === 1 ? (
                        <div className='pdf-preview-wrapper'>
                            <div className='pdf-preview-upper-block' style={{ backgroundColor: data.designPrimaryColor }}>
                                <div className='pdf-logo-block'>
                                    {data.logo ? (
                                        <img className='pdf-logo' src={typeof (data.logo) === 'string' ? data.logo : URL.createObjectURL(data.logo)} alt='' />
                                    ) : (
                                        <div className='pdf-logo-empty'></div>
                                    )}
                                </div>
                                <span className='pdf-company-name' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                                    {data.company ? data.company : t("companyName")}
                                </span>
                                <h5 className='pdf-title-text' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                                    {data.title ? data.title : t("pdfTitle")}
                                </h5>
                                <span className='pdf-company-description' style={{ color: data.designTextColor, wordBreak: 'break-all' }}>
                                    {data.description ? data.description : t("description")}
                                </span>
                                <button onClick={() => handleOpenFiles(data.files)} className='pdf-button' style={{ backgroundColor: data.designButtonColor }}>
                                    {data.button ? data.button : 'CTA'}
                                </button>
                            </div>
                            <div className='pdf-preview-lower-block' style={{ backgroundColor: data.designSecondaryColor }}>
                                <div className='pdf-image-block'>
                                    {data.image && (
                                        <img className='pdf-preview-img' src={typeof (data.image) === "string" ? data.image : URL.createObjectURL(data.image)} alt="" />
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{ backgroundColor: `${data.designPrimaryColor}` }}
                            className={`flex flex-col w-full items-center ${decreaseElementsSize ? 'absolute top-[0px] left-0 pt-[20px] min-h-full' : 'min-h-[100dvh]'}`}
                        >
                            <div
                                className={`flex items-center justify-center px-2 border-solid border-b-[1px] border-[#E2E8F0] gap-2 w-full ${decreaseElementsSize ? 'h-[38px]' : 'h-[44px]'}`}
                            >
                                <img
                                    className={`${decreaseElementsSize ? 'h-[20px]' : 'h-[28px]'}`}
                                    src={
                                        data.logo ?
                                            (typeof (data.logo) === 'string'
                                                ? data.logo
                                                : URL.createObjectURL(data.logo))
                                            : EmptyLogo
                                    }
                                    alt=""
                                />
                                <h6
                                    style={{ color: `${data.company ? `${data.designTextColor}` : '#14131580'}` }}
                                    className={`tracking-[-0.02em] font-bold ${decreaseElementsSize ? 'text-[16px] leading-[20px]' : 'text-[19px] leading-[23px]'}`}
                                >
                                    {data.company ? data.company : t("company")}
                                </h6>
                            </div>
                            <div className={`max-w-[600px] flex flex-col items-center w-full ${decreaseElementsSize ? 'p-[8px] pt-[20px] gap-[8px]' : 'p-[16px] pt-[40px] gap-[16px]'}`}>
                                <h6
                                    style={{ color: `${data.title ? `${data.designTextColor}` : '#14131580'}` }}
                                    className={`text-center tracking-[-0.04em] break-words ${decreaseElementsSize ? 'text-[20px] leading-[28px] font-semibold' : 'text-[34px] leading-[40px] font-bold'}`}
                                >
                                    {data.title ? data.title : t("pdfTitle")}
                                </h6>
                                <p
                                    style={{ color: `${data.description ? `${data.designTextColor}` : '#14131580'}` }}
                                    className={`break-words text-center ${decreaseElementsSize ? 'text-[14px] leading-[17px]' : 'text-[20px] leading-[24px]'}`}
                                >
                                    {data.description ? data.description : t("description")}
                                </p>
                                <button
                                    onClick={() => handleOpenFiles(data.files)}
                                    style={{
                                        backgroundColor: data.designButtonColor,
                                        color: data.designSecondaryColor
                                    }}
                                    className={`flex items-center justify-center gap-[6px] w-full rounded-lg font-semibold ${decreaseElementsSize ? 'text-[12px] h-[26px] mt-[4px] mb-[20px]' : 'text-[16px] h-[40px] mt-[8px] mb-[40px]'}`}
                                >
                                    {data.button ? data.button : t("button")}
                                </button>
                                <div className='w-full flex flex-col gap-[16px] rounded-lg overflow-hidden relative'>
                                    <img
                                        className='w-full'
                                        src={
                                            data.image
                                                ?
                                                typeof (data.image) === "string"
                                                    ? data.image
                                                    : URL.createObjectURL(data.image)
                                                : EmptyImage
                                        }
                                        alt=""
                                    />
                                    <div className={`absolute top-[8px] left-[8px] flex items-center justify-center gap-[4px] bg-[#00000080] ${decreaseElementsSize ? 'h-[26px] rounded-[4px] px-[4px]' : 'h-[36px] rounded-lg px-[8px]'}`}>
                                        <FaRegFile size={decreaseElementsSize ? 14 : 18} color="#FFFFFF" />
                                        <span className={`text-[#FFFFFF] ${decreaseElementsSize ? 'text-[12px] leading-[15px] font-medium' : 'text-[14px] leading-[17px] font-semibold'}`}>
                                            pdf_file.pdf
                                        </span>
                                    </div>
                                    <button onClick={() => handleOpenFiles(data.files)} className={`absolute bottom-[8px] right-[8px] flex items-center justify-center gap-[4px] bg-[#FFFFFF] ${decreaseElementsSize ? 'h-[26px] rounded-[4px] px-[4px]' : 'h-[36px] rounded-lg px-[8px]'}`}>
                                        <MdOutlineFileDownload size={decreaseElementsSize ? 18 : 20} color="#141315" />
                                        <span className={`text-[#141315] ${decreaseElementsSize ? 'text-[12px] leading-[15px] font-medium' : 'text-[14px] leading-[17px] font-semibold'}`}>
                                            Download
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div>
                    <img
                        src={
                            USER_CREATE_CODE_IMAGES_TRANSLATIONS[i18n.language]?.["PDF"]
                            ?? USER_CREATE_CODE_IMAGES_TRANSLATIONS['en']?.["PDF"]
                        }
                        alt=""
                    />
                </div>
            )}
        </>
    )
}

export default PdfPreview